import * as React from "react";

const PackageTypeItem = ({ imageSrc, title }) => (
  <div className="package-type-item">
    <img src={imageSrc} alt={title} className="package-type-image" />
    <div className="package-type-title">{title}</div>
  </div>
);

const packageTypes = [
  { imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/c13b39cf7d9b2b8b96291c62fb5ac497951dd3499c8f4f3b91826b680967d736?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&", title: "Sacchetti e shopper" },
  { imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/1ac722e209f81914a4f46575981fd3945d70e259d35086d6986e907b9b017f93?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&", title: "Fiori e piante" },
  { imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/b2262d9d883beceb3d22cf8a480252595b90d203202dcb51fbcc51817dceba1e?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&", title: "Pasticceria e Gelati" },
  { imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/714093ae1c9aaa5a7a347a1da72c3c50bd3c95153a3091f8f4a0011ed41612d3?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&", title: "Vini e bevande" },
  { imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/f8441318f7595ead5f5edda5a537c6759ff67cf6c1845ae9756324bae6695755?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&", title: "Scatole e pacchi 0/20kg" },
  { imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/c4f872e80c3355e8950c213370f645838654041570708e662784442710a75725?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&", title: "Libri riviste" },
  { imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/c4f872e80c3355e8950c213370f645838654041570708e662784442710a75725?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&", title: "Stampe Affiches quadri" },
  { imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/8bbe84e1a757b7c06b35e25a00c848a5bb67f49ab0669c4ea032d5e758d651b9?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&", title: "Buste e documenti" },
  { imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/dce544d5fe9f4da2006b9a66f847f2788d37b058aea7f47fb85ff422ad53d455?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&", title: "Altro" },
];

function BookingForm() {
  return (
    <>
      <div className="booking-form">
        
        <header className="booking-header">
        <div className="booking-header2"></div>
          <div className="booking-header-content">
            <div className="back-button">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7df1ebff0f4815f17ed6fb882cf73d7bb1c6ee0fa842c311d4343171abaaa775?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&" alt="Back" className="back-icon" />
            </div>
            <h1 className="booking-title">Prenota</h1>
          </div>
          <div className="booking-steps">
            <div className="booking-step active">Cosa ?</div>
            <div className="booking-step">Mittente</div>
            <div className="booking-step">Destinatario</div>
          </div>

        </header>
        <div>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/aadc0af97524888f231e1a8aaa8db19413fbc15428ebaf612b3ee5a676e43e6f?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&" alt="" className="question-icon" />
          <div className="question-mark">?</div>
          <h2 className="package-type-heading">Tipo di Collo</h2>
          <div className="package-type-list">
            {packageTypes.slice(0, 3).map((packageType, index) => (
              <PackageTypeItem key={index} {...packageType} />
            ))}
          </div>
          <div className="package-type-list">
            {packageTypes.slice(3, 6).map((packageType, index) => (
              <PackageTypeItem key={index} {...packageType} />
            ))}
          </div>
          <div className="package-type-list">
            {packageTypes.slice(6).map((packageType, index) => (
              <PackageTypeItem key={index} {...packageType} />
            ))}
          </div>
        </div>
        <div className="booking-actions">
          <button className="back-action">Indietro</button>
          <button className="next-action">Avanti</button>
        </div>
        <div className="progress-bar" />
      </div>
      <style jsx>{`
        .booking-form {
          border-radius: 30px;
          background-color: var(--main-color-Yellow-white, #fdfbf7);
          max-width: 480px;
          width: 100%;
          margin: 0 auto;
          padding-bottom: 100px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        
        .booking-header {
          border-radius: 50%;
          background-color:transparent !important;
          width: 100%;
          padding: 78px 24px 0;
          display: flex;
          flex-direction: column;
        }
        .booking-header2 {
          z-index:-1;
          border-radius: 50%;
          background-color:orange !important;
          width: 100%;
          height:100px;
          padding: 78px 24px 0;
          display: flex;
          position: absolute;
          flex-direction: column;
        }
        
        
        .booking-header-content {
          display: flex;
          gap: 20px;
        }
        
        .back-button {
          border-radius: 30px;
          background-color: var(--main-color-Yellow-700, #fddc79);
          width: 40px;
          height: 40px;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .back-icon {
          width: 20px;
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
        }
        
        .booking-title {
          color: var(--grey-scale-grey-1000, #1f2223);
          margin: auto 0;
          flex-grow: 1;
          font: 600 24px/115% Nunito, -apple-system, Roboto, Helvetica, sans-serif;
        }
        
        .booking-steps {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          margin-top: 20px;
          padding: 4px;
          font-size: 18px;
          color: var(--grey-scale-grey-700, #797a7b);
          font-weight: 600;
          line-height: 115%;
        }
        
        .booking-step {
          font-family: Nunito, sans-serif;
        }
        
        .booking-step.active {
          color: var(--grey-scale-grey-1000, #1f2223);
        }
        
        .question-icon {
          width: 8px;
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
        }
        
        .question-mark {
          border-radius: 30px;
          background-color: var(--main-color-Yellow-500, #fcf0cc);
          width: 30px;
          height: 30px;
          padding: 0 10px;
          margin-top: 24px;
          align-self: end;
          z-index: 10;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #7b7b7b;
          white-space: nowrap;
          font: 500 16px Inter, sans-serif;
        }
        
        .package-type-heading {
          color: #000;
          margin: -6px 0 0 16px;
          align-self: start;
          font: 600 24px/115% Nunito, -apple-system, Roboto, Helvetica, sans-serif;
        }
        
        .package-type-list {
          display: flex;
          justify-content: space-between;
          gap: 7px;
          margin-top: 24px;
          padding: 0 2px;
          font-size: 12px;
          color: #000;
          font-weight: 500;
          text-align: center;
        }
        
        .package-type-item {
          border-radius: 30px;
          background-color: var(--main-color-Yellow-300, #fefaf0);
          width: 110px;
          height: 110px;
          padding: 8px;
          display: flex;
          flex-direction: column;
        }
        
        .package-type-image {
          width: 60px;
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          align-self: center;
        }
        
        .package-type-title {
          margin-top: 4px;
          font-family: Inter, sans-serif;
        }
        
        .booking-actions {
          display: flex;
          gap: 16px;
          margin-top: 322px;
          width: 100%;
          max-width: 345px;
          padding: 24px 0;
          font-size: 16px;
          font-weight: 500;
          white-space: nowrap;
          z-index: 10;
        }
        
        .back-action {
          border-radius: 8px;
          border: 1px solid rgba(132, 132, 132, 1);
          color: #414141;
          padding: 10px 31px;
          font-family: Inter, sans-serif;
          justify-content: center;
          background: none;
          cursor: pointer;
        }
        
        .next-action {
          border-radius: 8px;
          background-color: var(--grey-scale-grey-600, #8e9090);
          color: #000;
          padding: 10px 60px;
          font-family: Inter, sans-serif;
          justify-content: center;
          align-items: center;
          border: none;
          cursor: pointer;
        }
        
        .progress-bar {
          border-radius: 100px;
          background-color: #6d6d6d;
          width: 134px;
          height: 5px;
        }
      `}</style>
    </>
  );
}

export default BookingForm