import logo from './logo.svg';

import {useState, useEffect}  from 'react'
import './App.css';
import mqtt from 'mqtt';
import axios from 'axios'
import LoadingAlert from './LoadingAlert'
import Wave from 'react-wavify';
import config from './config.json'

import { Container, Row, Col, Modal,Form, InputGroup, Button, FormControl, Alert } from 'react-bootstrap';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';
const devices_info=config
function App() {
    const apiKey = process.env.REACT_APP_PSW_INT  
    const ADMINPATH= process.env.REACT_APP_ADMIN_PATH 
    const USR_MQTT= process.env.REACT_APP_USR_MQTT 
    const PSW_MQTT= process.env.REACT_APP_PSW_MQTT 
    const PSW_RESET= process.env.REACT_APP_PSW_RESET  || "smartme"
    const [devicereset, setResetDevice]=useState("")
    const [client, setClient] = useState(null);
    const [show, setShow]=useState(false)
    const [pswReset, setPass]=useState("")
    const [connection, setConnectStatus]=useState(null)
    const [level_pu, SetLevel]= useState([]);
    const [error, setError] = useState(false);
    // const [client, SetClient]= useState(null)
    const [label, setLabel]= useState("OPEN")
    const [showLoading, setShowLoading]= useState(false);
    const [token, setToken]=useState("")
    const [admin, setAdmin]=useState(false)
    const [isOver, setover]=useState(false)
    const [indexIsland, setIndexIsland]=useState(undefined)
    const [indexDevice, setindexDevice]=useState(undefined)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const searchParams = new URLSearchParams(document.location.search);
    

    
    function mappingValue(x,  in_min,  in_max,  out_min,  out_max) {
          return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    }
    const params = new URLSearchParams(document.location.search);
    
    const mqttConnect = (host, mqttOption) => {
      setConnectStatus('Connecting');
      let options={
        username:USR_MQTT,
        password:PSW_MQTT,
        qos:0,
        clean:true,
        keepalive:60,
        protocol:'wss',
        clientId:'mqttjs_' + Math.random().toString(16).substr(2, 8),
      }
      console.log(host, options)
      setClient(mqtt.connect(host, options));
    };
    const mqttPublish = (context) => {
      if (client) {
        const { topic, qos, payload } = context;
        console.log(topic, qos, payload)
        client.publish(topic, payload, { qos }, error => {
        if (error) {
            console.log('Publish error: ', error);
          }
        });
      }
    };
    function trovaIsolaConId( idCercato) {
      console.log(idCercato)
      for (let i=0; i<devices_info.isole.length; i++){
        for(let j=0; j<devices_info.isole[i].devices.length; j++){
          if (devices_info.isole[i].devices[j].id === idCercato) {
            return [i, j]
            setIndexIsland(i)
            setindexDevice(j)
        }
        }
      }
      // for (const isola of devices_info.isole) {
      //     for (const device of isola.devices || []) {
      //         if (device.id === idCercato) {
      //             return [isola, device]
      //         }
      //     }
      // }
      return null;
  }
  
      useEffect(()=>{
       
          mqttConnect("wss://broker.fleet85.smartme.io:8083/ws", {})

          if(window.location.href.includes(ADMINPATH)){
            setAdmin(true)
            console.log("admin")
          }else{
            setAdmin(false)
            console.log("user")
          }
          

          try{
            let single_device= params.get("id");
            let island= params.get("island")
            let devices=[]
            let indexSingle
            if(single_device!=null){
              console.log("single id")
              console.log(single_device)
              let info= trovaIsolaConId(single_device)
              console.log(info)
              if(info!=null){
                setIndexIsland(info[0])
                indexSingle=info[1]
                setindexDevice(info[1])
                devices=devices_info.isole[info[0]]
              }
            }
            else{
              devices= devices_info.isole[params.get("island")]
              setIndexIsland(params.get("island"))
              if(params.get("id")!=undefined){
                setindexDevice(params.get("id"))
              }
            }

            
         
           
            console.log(devices)
            let keys_redis=[]
            devices.devices.map(item=>{
              keys_redis.push(item.id+"_level")
              keys_redis.push(item.id+"_zerolevel")
              keys_redis.push(item.id+"_counter")
            })
            axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
            {"action": "get", "args": {"key": keys_redis, "type": "appl"}},
            {
              headers:{
                "Authorization": apiKey
              }
            }).then(e=>{
              console.log(e.data.arancino.datastore.result)
              
              try{
                SetLevel(e.data.arancino.datastore.result)
                console.log("index device", indexSingle*3, (indexSingle*3)+1)
                let tmpVal=parseFloat(e.data.arancino.datastore.result[indexSingle*3])
                console.log("tmpVal",tmpVal)
                let zero=parseFloat(e.data.arancino.datastore.result[(indexSingle*3)+1])
                console.log(tmpVal)
                
                if(!isNaN(tmpVal))
                 
                  console.log(tmpVal, zero)
                  console.log("zero level "+ zero + "   valore distance  "+ tmpVal)
                  let value=mappingValue(tmpVal, zero,33.0, 0.0,100.0)
                  console.log(value)
                  if(value<0){
                    value=0.0
                  
                  }
                  else if(value>=100){
                    value=100;
                    
                    setover(true);


                  }
                  else{
                    // SetLevel(e.data.arancino.datastore.result)
                  }
                  console.log(value)
                 
              }catch(e){
                
              }
             
            }).catch(e=>{
              
            })
          
 
          }catch(e){
            console.log("error", e)
          }
            

        
        

    }, [])



    useEffect(()=>{
      console.log(client)
      console.log("use effect client")
      if(client!=null){
        if(isOver){
          console.log("over 100%")
          console.log("client ok")
          let msg=`{
            "message":"BIN FULL",
            "type": "alert",
            "source": "${params.get("id")}",
            "source_label": "Poopick 00"
            }`
            if(!admin){
              console.log("is over and not admin")
              axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
              {"action": "pub",
              "args": {
                  "items": [{
                      "channel": params.get("id")+"_full",
                      "message": "1"
                  }
                  ]
              }
              },
              {
                headers:{
                  "Authorization": apiKey
                }
              })
            }

          // mqttPublish({topic:"poopick_events/", qos:0,payload:msg })
        }
        // let msg=`{
        //   "message":"BIN FULL",
        //   "type": "alert",
        //   "source": "${params.get("id").split("/")[0]}",
        //   "source_label": "Poopick 00"
        //   }`
        // mqttPublish({topic:"poopick_events/", qos:0,payload:msg })
        // console.log("send message")
      }
    }, [client, isOver])

    function getIndex(id){

    }
    function getLevel(index){

      try{
        let tmp= mappingValue(level_pu[index*3], level_pu[index*3+1],33.0, 0.0,100.0).toFixed(0)
        console.log(tmp, index)
        console.log(level_pu[index*3], level_pu[index*3+1])
        if(tmp>100){
         
          return 100
          
        }
        else if(tmp<0){
          return 0
        }
        else if(isNaN(tmp)){
          return 0
        }
        else{
          return tmp
        }
      }
      catch(e){
        return 0
      }

    }
    function PasswordToReset(val){
      setPass(val)
    }
    function ResetConfig(){
  
      axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
      {"action": "del", "args": {"key":  [devicereset], "type": "appl"}},
       {
        headers:{
          "Authorization": apiKey
        }
      }).then(e=>{
        console.log("done")
      }).catch(e=>{
        console.log(e)
      })
    }
    function OpenModal(island, index){
      let devices= devices_info.isole[island]
      let item=devices.devices[index]
      setResetDevice(item.id+"_zerolevel")
      setShow(true)
    }
    function Door(island, index){
      let devices= devices_info.isole[island]
      let item=devices.devices[index]
      const params = new URLSearchParams(document.location.search);
      if(level_pu[index*3] || admin){
        axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
        {"action": "pub",
        "args": {
            "items": [{
                "channel": item.id+"_prc",
                "message": "1"
            }
            ]
        }
      },
         {
          headers:{
            "Authorization": apiKey
          }
        }).then(e=>{
          
        }).catch(e=>{
         
        })
        setShowLoading(true)

        setTimeout(()=>{
          setShowLoading(false)
        },10000)
      }

    
      // arancino/cortex/87654321/

     



    }

    function CheckPsw(){
      console.log(PSW_RESET)
      if(pswReset==PSW_RESET){

        ResetConfig()
        handleClose()

      }else{
        alert("Password is not Correct")
        setError(true)
      }
    }




  return (
    <div className="App">
      <header >
        <img width={"100px"}src="poopick.png"></img>
        <h3>Enviroment and Waste Management</h3>
      </header>
    
      <body >
      {!admin ? indexIsland==undefined || indexDevice==undefined ? <> <p>No device selected or Not Found</p></>:
              <Container>
                  <Row>
                    <Col>
                      <div>
                        <div class="bodyApp">
                          <div class="Ocean">
                            <Wave fill="forestgreen"
                                  paused={false}
                            
                                  style={{ display: 'flex', fill:"forestgreen" }}
                                  options={{
                                    height: 100,
                                    amplitude: 10,
                                    speed: 0.25,
                                    points: 5,
                                    fill: "forestgreen"
                                  }}
                            />
                            <div class="Level" style={{height:getLevel(indexDevice)+"%"}} ></div>
                          </div>
                        </div>
                        <p class="precent">{getLevel(indexDevice)}%</p>
                        <div class="ButtonDiv">
                          <button disabled={getLevel(indexDevice)>=100 && !admin} class="Button" onClick={e=>{Door(indexIsland,indexDevice)}}>
                            <div className='BtnDiv'>
                              <img width={"30px"} src='/power.png'/>{label}
                            </div>
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container> 
      : indexIsland==undefined ? <><p>No device selected or Not Found</p></> :
            <Container>
              <Row>
                <strong><h1 style={{color:'grey'}}>{devices_info.isole[indexIsland]?.nome}</h1></strong>
              </Row>
              <Row>
                {devices_info.isole[indexIsland].devices!=undefined ? devices_info.isole[indexIsland]?.devices.map((item, index)=>(
                  <Col>
                    <h3 class="counter">N° {level_pu[index*3+2]!= undefined ?level_pu[index*3+2] :0}</h3>
                    <div class="bodyApp">
                      <div class="Ocean">
                        <Wave fill={item.enable ? "forestgreen": "gray"}
                              paused={false}
                        
                              style={{ display: 'flex', fill:"forestgreen" }}
                              options={{
                                height: 100,
                                amplitude: 10,
                                speed: 0.25,
                                points: 5,
                                fill: "forestgreen"
                              }}/>
                        <div class={item?.enable ? "Level": "Level_disabled"}  style={{height:item.enable ? getLevel(index)+"%": "10%"}} ></div> 
                      </div>
                    </div>
                    <p class="precent">{item?.enable ? getLevel(index): ""}%</p>
                    <div class="ButtonDiv">
                      <button disabled={getLevel(index)>=100 && !admin || !item?.enable} class="Button"  onClick={e=>{Door(indexIsland, index)}}>
                        <div className='BtnDiv'>
                         <img width={"30px"} src='/power.png'/>{label}
                        </div>
                      </button>
                      <div className='separate'></div>
                      <button disabled={getLevel(index)>=100 && !admin || !item?.enable} class=" delete"  onClick={e=>{OpenModal(indexIsland, index)}}>
                        <div className='BtnDiv'>
                         <img width={"30px"} src='/power.png'/>Reset
                        </div>
                      </button>
                      <h1 style={{padding:"10px", color:"forestgreen"}}>{item?.nome}</h1>
                    </div>
                  </Col>
                )) : <>
                  
                </>}
              </Row>
            </Container>}
      </body>
      <LoadingAlert show={showLoading}/>
      <Modal show={show} >
        <Modal.Header closeButton>
          <Modal.Title>Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>Insert Password to delete the <strong>Calibration</strong>


      <Form>
        <Form.Group >
          <Form.Label>Inserisci la Password</Form.Label>
          <InputGroup>
            <InputGroup.Text>🔒</InputGroup.Text>
            <FormControl
            id="passwordInput"
            type="password"
            value={pswReset}
            onChange={e=>{PasswordToReset(e.target.value)}}
            invalid={true} // Mostra il bordo rosso se c'è un errore
            placeholder="Password"
            />

          </InputGroup>
        </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={e=>{CheckPsw()}}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
   
  );
}

export default App;
