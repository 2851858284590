import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useEffect } from 'react';


function LoadingAler(props){



    useEffect(()=>{
        console.log(props)
    },[props] )
    return (
        
        <Modal show={props.show} variant="light" style={{justifyContent: "center"}} centered backdrop={"static"}>
            <Modal.Header><Modal.Title>Loading</Modal.Title></Modal.Header>
            <Modal.Body><Spinner animation="grow" variant="info" /></Modal.Body>
            
        </Modal>
         
        
    )
}
export default LoadingAler;